<template lang="html">
  <div class="all">
    <Offer v-if="offer && Object.keys(offer).length" :key="offer.id || 'default'" :isVta='isVtaSL' :data='offer' :onSubmit='submit' :placement='placement' />
  </div>
</template>

<script>
import Offer from '../components/Offer'

export default {
  components: { Offer },
  data() {
    return {
      offer: {},
      placement: null,
    }
  },
  created() {
    this.updateOffer()
  },
  watch: {
    async offerId(val) {
      await this.updateOffer()
    }
  },
  computed: {
    offerId() {
      return this.$route.params.offerId
    },
    isVtaSL() {
      let { campId, placementId, offerId } = this.$route.params
      let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
      this.offer = placement.offers.find(v => v.id == offerId)
      return this.offer && this.offer.click_offer_id !== 0

    }
  },
  methods: {
    async updateOffer() {
      let { campId, placementId, offerId } = this.$route.params;
      console.log('[+] Fetching offers extra sub bl wl');
      const offerExtraSubBlWl = await this.$http.get(this.resources.SmartLinksCampaigns.getOfferExtraSubBlWl(campId, placementId, offerId));
      let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId);
      let offer = placement.offers.find(v => v.id == offerId);
      if (offer) {
        const blacklisting_subs = offer.blacklisting_subs;
        blacklisting_subs.forEach(element => {
          element.extra_sub_list = offerExtraSubBlWl.data.filter(v => v.sub == element.sub);
        });
        offer.blacklisting_subs = blacklisting_subs;
      }
      this.placement = placement
      this.offer = offer || {};
    },
    submit(form) {
      console.log('[+] Submitting offer');
      let { campId, placementId, offerId } = this.$route.params
      const form_copy = JSON.parse(JSON.stringify(form))

      for (let curr_blacklist of form_copy.blacklisting_subs) {
        delete curr_blacklist.extra_sub_list
      }

      this.$http.put(this.resources.SmartLinksCampaigns.updateOffer(campId, placementId, offerId), form_copy)
        .then(() => {
          let { campId, placementId, offerId } = this.$route.params
          let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
          let offer = placement.offers.find(v => v.id == offerId)

          Object.assign(offer, form_copy)
        }).then(async () => {
          try {
            const offers_extra_blwl = form.blacklisting_subs.map(v => v.extra_sub_list).flat()
            offers_extra_blwl.forEach(v => {
              if (typeof v.offer_id === 'number') {
                v.offer_id = v.offer_id.toString()
              }
              if (typeof v.sl_id === 'number') {
                v.sl_id = v.sl_id.toString()
              }
              if (typeof v.placement_id === 'number') {
                v.placement_id = v.placement_id.toString()
              }
            })
            console.log('[+] Updating offers extra sub bl wl');
            await this.$http.post(this.resources.SmartLinksCampaigns.updateOfferExtraSubBlWl(campId, placementId, offerId), offers_extra_blwl)
            this.notifySuccess("Offer and extra sub blacklists/whitelists have been updated")
            await this.updateOffer();
          } catch (err) {
            throw err
          }
        })
        .catch(err => {
          this.notifyError("Error has occurred, offer or extra sub blacklists/whitelists have not been updated")
        })
    }
  }
}
</script>

<style lang="css"></style>
